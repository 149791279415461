import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import { TrackingProvider } from 'src/context/TrackingProvider';
import { 
	Account, AccountEdit, AccountDuplicate, AccountDelete,
	Bot, BotEdit, BotTelegramStatus,
	Client, ClientEdit, ClientDefaultGroups, ClientConfig, ClientMacros,
	Command, CommandEdit, CommandLastSent,
	Contact, ContactEdit, ContactDelete, ContactContactGroup,
	ContactGroup, ContactGroupEdit, ContactGroupContacts,
	Dashboard,
	Driver, DriverEdit, DriverEditApp,
	Event,
	Fence, FenceEdit, FenceVehicleWithin, FenceVehicles, FenceVehicleWithinReport,
	GenericCommand, GenericCommandEdit,
	GenericCommandPackage,
	Grid, GridEdit, GridUsers, GridColumns,
	Group, GroupEdit, GroupEditAutoReport, GroupUsers, GroupVehicles, GroupSendMessage, GroupPositionReport, GroupSpeedReport, GroupEventTypeHeatmap,
	IdleTime,
    Import,
	Inhibition, InhibitionEdit, InhibitionDelete,
    Insurer, InsurerEdit,
    Insurance, InsuranceEdit,
    KindEvent, KindEventEdit, KindEventMarkReadByKind, KindEventRiskManagement,
	KindMarker, KindMarkerEdit,
	KindOccurrence, KindOccurrenceEdit,
	KindOperation, KindOperationEdit,
	KindRisk, KindRiskEdit,
	KindTruckBody, KindTruckBodyEdit, KindUserMenuItem,
	KindUser, KindUserEdit, KindUserDuplicate, KindUserDelete, KindUserKindOfEvents,
	LoginPage,
	Macro, MacroEdit,
	MacroItem, MacroItemEdit, MacroItemDelete,
	Map, MapMarkerViewer, MapTripTester,
	Mapserver, MapserverEdit,
	Message,
	Occurrence, OccurrenceEdit, OccurrenceRead,
	Page403, Page404,
    Platform, PlatformEdit,
    Procedure, ProcedureEdit,
	Reference, ReferenceEdit, ReferenceKindOfEvent, ReferenceTracking, ReferenceTrigger, ReferenceLatLng,
	ReportTrip, ReportTripSendMail, ReportTemperatureControl,
    RiskManagement, RiskManagementEdit, RiskManagementDuplicate, RiskManagementDelete,
	RiskManagementEvents, RiskManagementEventsManager, RiskManagementEventsEdit,
	ScheduledTrip, ScheduledTripConfirm,
    TemperatureRange, TemperatureRangeEdit,
    Test, TestSocket, 
	Tracking,
	Tracker, TrackerEdit, TrackerDelete, TrackerTestJig, TrackerLog, TrackerDebug,
	TrackerSystem, TrackerSystemEdit, TrackerSystemProduct,
	Trailer, TrailerEdit,
	Transittime,
	Trip, TripAdd, TripFinish, TripCancel, 
	User, UserEdit, UserDelete, UserProfile, UserGroups, UserGrids, UserLog, UserEditGroup, UserEditGrid, UserSecret,
	Vehicle, VehicleEdit, VehicleGroups, VehicleTrackers, VehicleTrailers, VehicleMercosul, VehicleLog, UserLogout, UserEditKindOfUser, MacroItemInsertBy,
} from './elements';
import { MessagesProvider } from 'src/context/MessagesProvider';
import { EventsProvider } from 'src/context/EventsProvider';

export default function Router() 
{
	return useRoutes([
		{
			path: '/',
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				{
					path: 'login',
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
			],
		},
		{
			//path: '/dashboard',
			path: '/',
			element: (
				<AuthGuard>
					<TrackingProvider>
						<MessagesProvider>
							<EventsProvider>
								<DashboardLayout />
							</EventsProvider>
						</MessagesProvider>
					</TrackingProvider>
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				//{ path: 'one', element: <PageOne /> },
				{
					path: 'account',
					children: [
						{ element: <Navigate to="/account/list" replace />, index: true },
						{ path: 'list', element: <Account /> },
						{ path: 'insert', element: <AccountEdit /> },
						{ path: 'edit/:id', element: <AccountEdit /> },
						{ path: 'delete/:id', element: <AccountDelete /> },
						{ path: 'duplicate/:id', element: <AccountDuplicate /> },
					],
				},
				{
					path: 'bot',
					children: [
						{ element: <Navigate to="/bot/list" replace />, index: true },
						{ path: 'list', element: <Bot /> },
						{ path: 'insert', element: <BotEdit /> },
						{ path: 'edit/:id', element: <BotEdit /> },
						{ path: 'telegram-status/:id', element: <BotTelegramStatus /> },
					],
				},
				{
					path: 'client',
					children: [
						{ element: <Navigate to="/client/list" replace />, index: true },
						{ path: 'list', element: <Client /> },
						{ path: 'insert', element: <ClientEdit /> },
						{ path: 'edit/:id', element: <ClientEdit /> },
						{ path: 'defaultGroups/:id', element: <ClientDefaultGroups /> },
						{ path: 'macros/:id', element: <ClientMacros /> },
						{ path: 'config/:id', element: <ClientConfig /> },
					],
				},
				{
					path: 'command',
					children: [
						{ element: <Navigate to="/command/list" replace />, index: true },
						{ path: 'list', element: <Command /> },
						{ path: 'list/genericCommand/:id', element: <Command /> },
						{ path: 'insert', element: <CommandEdit /> },
						{ path: 'edit/:id', element: <CommandEdit /> },
						{ path: 'lastsent', element: <CommandLastSent /> },
					],
				},
				{
					path: 'contact',
					children: [
						{ element: <Navigate to="/contact/list" replace />, index: true },
						{ path: 'list', element: <Contact /> },
						{ path: 'insert', element: <ContactEdit /> },
						{ path: 'edit/:id', element: <ContactEdit /> },
						{ path: 'delete/:id', element: <ContactDelete /> },
						{ path: 'contactGroup/:id', element: <ContactContactGroup /> },
					],
				},
				{
					path: 'contactGroup',
					children: [
						{ element: <Navigate to="/contactGroup/list" replace />, index: true },
						{ path: 'list', element: <ContactGroup /> },
						{ path: 'insert', element: <ContactGroupEdit /> },
						{ path: 'edit/:id', element: <ContactGroupEdit /> },
						{ path: 'contacts/:id', element: <ContactGroupContacts /> },
					],
				},
				{
					path: 'dashboard',
					children: [
						{ element: <Navigate to="/dashboard" replace />, index: true },
						{ path: 'list', element: <Dashboard /> },
					],
				},
				{
					path: 'driver',
					children: [
						{ element: <Navigate to="/driver/list" replace />, index: true },
						{ path: 'list', element: <Driver /> },
						{ path: 'insert', element: <DriverEdit /> },
						{ path: 'edit/:id', element: <DriverEdit /> },
						{ path: 'edit/app/:id', element: <DriverEditApp /> },
					],
				},
				{
					path: 'event',
					children: [
						{ element: <Navigate to="/event/list" replace />, index: true },
						{ path: 'list', element: <Event /> },
						{ path: 'list/:id', element: <Event /> },
					],
				},
				{
					path: 'fence',
					children: [
						{ element: <Navigate to="/fence/list" replace />, index: true },
						{ path: 'list', element: <Fence /> },
						{ path: 'insert', element: <FenceEdit /> },
						{ path: 'edit/:id', element: <FenceEdit /> },
						{ path: 'vehicles/:id', element: <FenceVehicles /> },
						{ path: 'vehicle/within/:id', element: <FenceVehicleWithin /> },
						{ path: 'vehicle/within/report/:id', element: <FenceVehicleWithinReport /> },
					],
				},
				{
					path: 'genericCommand',
					children: [
						{ element: <Navigate to="/genericCommand/list" replace />, index: true },
						{ path: 'list', element: <GenericCommand /> },
						{ path: 'insert', element: <GenericCommandEdit /> },
						{ path: 'edit/:id', element: <GenericCommandEdit /> },
					],
				},
				{
					path: 'genericCommandPackage',
					children: [
						{ element: <Navigate to="/genericCommandPackage/list" replace />, index: true },
						{ path: 'list/:id', element: <GenericCommandPackage /> },
					],
				},
				{
					path: 'grid',
					children: [
						{ element: <Navigate to="/grid/list" replace />, index: true },
						{ path: 'list', element: <Grid /> },
						{ path: 'insert', element: <GridEdit /> },
						{ path: 'edit/:id', element: <GridEdit /> },
						{ path: 'users/:id', element: <GridUsers /> },
						{ path: 'columns/:id', element: <GridColumns /> },
					],
				},
				{
					path: 'group',
					children: [
						{ element: <Navigate to="/group/list" replace />, index: true },
						{ path: 'list', element: <Group /> },
						{ path: 'insert', element: <GroupEdit /> },
						{ path: 'edit/:id', element: <GroupEdit /> },
						{ path: 'edit/auto-report/:id', element: <GroupEditAutoReport /> },
						{ path: 'users/:id', element: <GroupUsers /> },
						{ path: 'vehicles/:id', element: <GroupVehicles /> },
						{ path: 'send-message/:id', element: <GroupSendMessage /> },
						{ path: 'report/position/:id', element: <GroupPositionReport /> },
						{ path: 'report/speed/:id', element: <GroupSpeedReport /> },
						{ path: 'heatmap/eventtype/:id', element: <GroupEventTypeHeatmap /> },
					],
				},
	            {
					path: 'idletime',
					children: [
						{ element: <Navigate to="/idletime" replace />, index: true },
						{ path: 'list', element: <IdleTime /> },
					],
				},
                {
					path: 'import',
					children: [
						{ element: <Navigate to="/import/upload" replace />, index: true },
						{ path: 'upload', element: <Import /> },
					],
				},
				{
					path: 'inhibition',
					children: [
						{ element: <Navigate to="/inhibition/list" replace />, index: true },
						{ path: 'list', element: <Inhibition /> },
						{ path: 'list/vehicle/:id', element: <Inhibition /> },
						{ path: 'list/tracker/:id', element: <Inhibition /> },
						{ path: 'insert', element: <InhibitionEdit /> },
						{ path: 'insert/vehicle/:id', element: <InhibitionEdit /> },
						{ path: 'edit/:id', element: <InhibitionEdit /> },
						{ path: 'delete/:id', element: <InhibitionDelete /> },
					],
				},
                {
					path: 'insurer',
					children: [
						{ element: <Navigate to="/insurer/list" replace />, index: true },
						{ path: 'list', element: <Insurer /> },
						{ path: 'insert', element: <InsurerEdit /> },
						{ path: 'edit/:id', element: <InsurerEdit /> },
					],
				},
                {
					path: 'insurance',
					children: [
						{ element: <Navigate to="/insurance/list" replace />, index: true },
						{ path: 'list', element: <Insurance /> },
						{ path: 'insert', element: <InsuranceEdit /> },
						{ path: 'edit/:id', element: <InsuranceEdit /> },
					],
				},
				{
					path: 'kindOfEvent',
					children: [
						{ element: <Navigate to="/kindOfEvent/list" replace />, index: true },
						{ path: 'list', element: <KindEvent /> },
						{ path: 'insert', element: <KindEventEdit /> },
						{ path: 'edit/:id', element: <KindEventEdit /> },
						{ path: 'mark-read-by-kind/:id', element: <KindEventMarkReadByKind /> },
						{ path: 'riskManagement/:id', element: <KindEventRiskManagement /> },
					],
				},
				{
					path: 'kindOfMarker',
					children: [
						{ element: <Navigate to="/kindOfMarker/list" replace />, index: true },
						{ path: 'list', element: <KindMarker /> },
						{ path: 'insert', element: <KindMarkerEdit /> },
						{ path: 'edit/:id', element: <KindMarkerEdit /> },
					],
				},
				{
					path: 'kindOfOccurrence',
					children: [
						{ element: <Navigate to="/kindOfOccurrence/list" replace />, index: true },
						{ path: 'list', element: <KindOccurrence /> },
						{ path: 'insert', element: <KindOccurrenceEdit /> },
						{ path: 'edit/:id', element: <KindOccurrenceEdit /> },
					],
				},
				{
					path: 'kindOfOperation',
					children: [
						{ element: <Navigate to="/kindOfOperation/list" replace />, index: true },
						{ path: 'list', element: <KindOperation /> },
						{ path: 'insert', element: <KindOperationEdit /> },
						{ path: 'edit/:id', element: <KindOperationEdit /> },
					],
				},
				{
					path: 'kindOfRisk',
					children: [
						{ element: <Navigate to="/kindOfRisk/list" replace />, index: true },
						{ path: 'list', element: <KindRisk /> },
						{ path: 'insert', element: <KindRiskEdit /> },
						{ path: 'edit/:id', element: <KindRiskEdit /> },
					],
				},
				{
					path: 'kindOfTruckBody',
					children: [
						{ element: <Navigate to="/kindOfTruckBody/list" replace />, index: true },
						{ path: 'list', element: <KindTruckBody /> },
						{ path: 'insert', element: <KindTruckBodyEdit /> },
						{ path: 'edit/:id', element: <KindTruckBodyEdit /> },
					],
				},
				{
					path: 'kindOfUser',
					children: [
						{ element: <Navigate to="/kindOfUser/list" replace />, index: true },
						{ path: 'list', element: <KindUser /> },
						{ path: 'insert', element: <KindUserEdit /> },
						{ path: 'edit/:id', element: <KindUserEdit /> },
						{ path: 'duplicate/:id', element: <KindUserDuplicate /> },
						{ path: 'delete/:id', element: <KindUserDelete /> },
						{ path: 'menuitem/:id', element: <KindUserMenuItem /> },
						{ path: 'kindOfEvents/:id', element: <KindUserKindOfEvents /> },
					],
				},
				{
					path: 'macro',
					children: [
						{ element: <Navigate to="/macro/list" replace />, index: true },
						{ path: 'list', element: <Macro /> },
						{ path: 'insert', element: <MacroEdit /> },
						{ path: 'edit/:id', element: <MacroEdit /> },
					],
				},
				{
					path: 'macro_item',
					children: [
						{ element: <Navigate to="/macro_item/list" replace />, index: true },
						{ path: 'list/:id', element: <MacroItem /> },
						{ path: 'macro/insert/:id', element: <MacroItemInsertBy /> },
						{ path: 'edit/:id', element: <MacroItemEdit /> },
						{ path: 'delete/:id', element: <MacroItemDelete /> },
					],
				},
                {
					path: 'map',
					children: [
						{ element: <Navigate to="/map/list" replace />, index: true },
						{ path: 'markerViewer', element: <MapMarkerViewer /> },
						{ path: 'tripTester', element: <MapTripTester /> },
					],
				},

				{
					path: 'mapserver',
					children: [
						{ element: <Navigate to="/mapserver/list" replace />, index: true },
						{ path: 'list', element: <Mapserver /> },
						{ path: 'insert', element: <MapserverEdit /> },
						{ path: 'edit/:id', element: <MapserverEdit /> },
					],
				},
				{
					path: 'message',
					children: [
						{ element: <Navigate to="/message/list" replace />, index: true },
						{ path: 'list', element: <Message /> },
						// { path: 'new', element: <Message /> },
						{ path: 'list/:id', element: <Message /> },
					],
				},
				{
					path: 'occurrence',
					children: [
						{ element: <Navigate to="/occurrence/list" replace />, index: true },
						{ path: 'list', element: <Occurrence /> },
						{ path: 'list/vehicle/:id', element: <Occurrence /> },
						{ path: 'insert', element: <OccurrenceEdit /> },
						{ path: 'insert/:vehicle_id', element: <OccurrenceEdit /> },
						{ path: 'edit/:id', element: <OccurrenceEdit /> },
						{ path: 'read/:id', element: <OccurrenceRead /> },
					],
				},
                {
					path: 'platform',
					children: [
						{ element: <Navigate to="/platform/list" replace />, index: true },
						{ path: 'list', element: <Platform /> },
						{ path: 'insert', element: <PlatformEdit /> },
						{ path: 'edit/:id', element: <PlatformEdit /> },
					],
				},
				{
					path: 'procedure',
					children: [
						{ element: <Navigate to="/procedure/list" replace />, index: true },
						{ path: 'list', element: <Procedure /> },
						{ path: 'insert', element: <ProcedureEdit /> },
						{ path: 'edit/:id', element: <ProcedureEdit /> },
					],
				},
				{
					path: 'reference',
					children: [
						{ element: <Navigate to="/reference/list" replace />, index: true },
						{ path: 'list', element: <Reference /> },
						{ path: 'insert', element: <ReferenceEdit /> },
						{ path: 'insert/:lat/:lng/:name', element: <ReferenceEdit /> },
						{ path: 'edit/:id', element: <ReferenceEdit /> },
						{ path: 'latlng/:id', element: <ReferenceLatLng /> },
						{ path: 'kindOfEvent/:id', element: <ReferenceKindOfEvent /> },
						{ path: 'tracking/:id', element: <ReferenceTracking /> },
						{ path: 'trigger/:id', element: <ReferenceTrigger /> },
					],
				},
				{
					path: 'report',
					children: [
						{ element: <Navigate to="/report" replace />, index: true },
					    { path: 'trip/:id', element: <ReportTrip /> },
					    { path: 'trip/sendmail/:id', element: <ReportTripSendMail /> },
                        { path: 'temperature/control/:id', element: <ReportTemperatureControl /> }
                    ],
				},
				{
					path: 'risk-management',
					children: [
						{ element: <Navigate to="/risk-management/list" replace />, index: true },
						{ path: 'list', element: <RiskManagement /> },
						{ path: 'list/client/:id', element: <RiskManagement /> },
						{ path: 'insert', element: <RiskManagementEdit /> },
						{ path: 'edit/:id', element: <RiskManagementEdit /> },
						{ path: 'duplicate/:id', element: <RiskManagementDuplicate /> },
						{ path: 'delete/:id', element: <RiskManagementDelete /> },
					],
				},
				{
					path: 'risk-management-events',
					children: [
						{ element: <Navigate to="/risk-management-events/list" replace />, index: true },
						{ path: 'list', element: <RiskManagementEvents/> },
						{ path: 'manager/:id', element: <RiskManagementEventsManager /> },
						// { path: 'list', element: <RiskManagementEvents /> },
						// { path: 'insert', element: <RiskManagementEventsEdit /> },
						{ path: 'edit/:id', element: <RiskManagementEventsEdit /> },
					],
				},
				{
					path: 'temperatureRange',
					children: [
						{ element: <Navigate to="/temperatureRange/list" replace />, index: true },
						{ path: 'list', element: <TemperatureRange /> },
						{ path: 'list/client/:id', element: <TemperatureRange /> },
						{ path: 'insert', element: <TemperatureRangeEdit /> },
						{ path: 'edit/:id', element: <TemperatureRangeEdit /> },
					],
				},
    	        {
					path: 'scheduled-trip',
					children: [
						{ element: <Navigate to="/scheduled-tip/list" replace />, index: true },
						{ path: 'list', element: <ScheduledTrip /> },
						{ path: 'confirm/:id', element: <ScheduledTripConfirm /> },
					],
				},
				{
					path: 'tracker',
					children: [
						{ element: <Navigate to="/tracker/list" replace />, index: true },
						{ path: 'list', element: <Tracker /> },
						{ path: 'list/client/:id', element: <Tracker /> },
						{ path: 'insert', element: <TrackerEdit /> },
						{ path: 'edit/:id', element: <TrackerEdit /> },
						{ path: 'delete/:id', element: <TrackerDelete /> },
						{ path: 'testjig', element: <TrackerTestJig /> },
						{ path: 'log/:id', element: <TrackerLog /> },
						{ path: 'debug/:id', element: <TrackerDebug /> },
					],
				},
				{
					path: 'tracker-system',
					children: [
						{ element: <Navigate to="/tracker-system/list" replace />, index: true },
						{ path: 'list', element: <TrackerSystem /> },
						{ path: 'edit/:id', element: <TrackerSystemEdit /> },
						{ path: 'product/:id', element: <TrackerSystemProduct /> },
					],
				},
				{
					path: 'tracking',
					children: [
						{ element: <Navigate to="/tracking" replace />, index: true },
						{ path: 'list', element: <Tracking /> },
						{ path: 'list/:filter/:keyword', element: <Tracking /> },
					],
				},
				{
					path: 'trailer',
					children: [
						{ element: <Navigate to="/trailer/list" replace />, index: true },
						{ path: 'list', element: <Trailer /> },
						{ path: 'insert', element: <TrailerEdit /> },
						{ path: 'edit/:id', element: <TrailerEdit /> },
					],
				},
				{
					path: 'transittime',
					children: [
						{ element: <Navigate to="/transittime" replace />, index: true },
						{ path: 'list', element: <Transittime /> },
					],
				},
				{
					path: 'trip',
					children: [
						{ element: <Navigate to="/trip" replace />, index: true },
						{ path: 'list', element: <Trip /> },
						{ path: 'insert/:id', element: <TripAdd /> },
						{ path: 'finish', element: <TripFinish /> },
						{ path: 'cancel', element: <TripCancel /> },
					],
				},
				{
					path: 'user',
					children: [
						{ element: <Navigate to="/user/list" replace />, index: true },
						{ path: 'list', element: <User /> },
						{ path: 'insert', element: <UserEdit /> },
						{ path: 'edit/:id', element: <UserEdit /> },
						{ path: 'edit/KindOfUser/:id', element: <UserEditKindOfUser /> },
						{ path: 'edit/group/:id', element: <UserEditGroup /> },
						{ path: 'edit/grid/:id', element: <UserEditGrid /> },
						{ path: 'delete/:id', element: <UserDelete /> },
						{ path: 'profile', element: <UserProfile /> },
						{ path: 'groups/:id', element: <UserGroups /> },
						{ path: 'grids/:id', element: <UserGrids /> },
						{ path: 'logout', element: <UserLogout /> },
						{ path: 'log/:id', element: <UserLog /> },
						{ path: 'secret/:id', element: <UserSecret /> },
					],
				},
				{
					path: 'vehicle',
					children: [
						{ element: <Navigate to="/vehicle/list" replace />, index: true },
						{ path: 'list', element: <Vehicle /> },
						{ path: 'list/client/:id', element: <Vehicle /> },
						{ path: 'insert', element: <VehicleEdit /> },
						{ path: 'edit/:id', element: <VehicleEdit /> },
						{ path: 'groups/:id', element: <VehicleGroups /> },
						{ path: 'trackers/:id', element: <VehicleTrackers /> },
						{ path: 'trailers/:id', element: <VehicleTrailers /> },
						{ path: 'mercosul/:id', element: <VehicleMercosul /> },
						{ path: 'log/:id', element: <VehicleLog /> },
					],
				},
                {
					path: 'test',
					children: [
						{ element: <Navigate to="/test/test" replace />, index: true },
						{ path: 'test', element: <Test /> },
						{ path: 'socket', element: <TestSocket /> },
					],
				},
			],
		},
		{
			element: <CompactLayout />,
			children: [
				{ path: '403', element: <Page403 /> },
				{ path: '404', element: <Page404 /> },
			],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
		{ path: '403', element: <Navigate to="/403" replace /> },
	]);
}
